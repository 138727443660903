<template>
  <v-container fluid class="px-0">
    <MyDataTable
      :title="'سرفصل های موجود ' + (course.title ? ' - ' + course.title : '')"
      subtitle="مدیریت سرفصل"
      addtitle="ایجاد سرفصل جدید"
      :addpageRoute="addPageRoute"
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      @onDeleteClick="deleteItem"
      @onEditClick="onEditClick"
      class="mt-n13 moholand-shadow"
    >
      <template #contentTag="{ row }">
        <td>{{ row.title }}</td>
        <td>{{ row.is_locked ? "بله" : "خیر" }}</td>
        <td>{{ row.gallery ? row.gallery.length : 0 }}</td>
        <td>{{ row.order || "-" }}</td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDataTable from "@/components/MyDataTable";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDataTable,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      let params = {};
      if (this.course_id)
        params = {
          conditions: {
            course_id: this.course_id,
          },
          with: "course",
        };

      MyAxios.get("/chapters/index", {
        params: {
          ...params,
          page: this.$route.query?.page || 1,
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
          if (this.items[0]) this.course = this.items[0].course || {};
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onEditClick(item) {
      this.$router.push({
        name: "ChaptersEdit",
        params: { ...this.$route.params, id: item.id },
        query: this.$route.query || {},
      });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.detailsModal.dialog = true;
    },
    deleteItem(item) {
      MyAxios.delete(`/chapters/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    onDetailsClick(item) {
      this.selectedData = item;
      this.$refs.productsModal.dialog = true;
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      course_id: this.$route.params.course_id || null,
      addPageRoute: `${this.$route.fullPath.slice(
        0,
        this.$route.fullPath.indexOf("index") - 1
      )}/create${
        this.$route.params.course_id ? "/" + this.$route.params.course_id : ""
      }${this.$route.query.page ? "?page=" + this.$route.query.page : ""}`,
      course: {},
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/chapters/index",
      pathParams: {},
      headers: [
        {
          text: "نام سرفصل",
          align: "start",
          value: "name",
        },
        { text: "قفل", value: "is_locked" },
        { text: "تعداد ویدئو", value: "gallery" },
        { text: "ترتیب", value: "order" },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
